<template>
  <a-modal v-model="visible" title="添加直播课"
           :mask-closable="false" @ok="handleOk" @cancel="clear">
    <a-form class="ant-advanced-search-form" :form="form" @submit="handleSearch" v-bind="formItemLayout">
      <a-form-item label="选择直播课">
        <a-select
          show-search
          v-model="courseid"
          placeholder="请输入关键词"
          style="width: 200px"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearch"
          @change="handleChange"
        >
          <a-select-option v-for="d in data" :key="d.courseid">
            {{ d.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="讲师">
        <a-input placeholder="请输入" v-model="form.teacher_title"/>
      </a-form-item>
      <a-form-item label="选择班次">
        <a-select placeholder="请选择" v-model="classes_id" mode="multiple">
          <a-select-option v-for="item in selectClassList" :value="item.class_id" :key="item.class_id">
            {{ item.class_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { getCourseList, liveClassAdd, getClassesSelectList } from '@/api/config';

let timeout;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  function fake() {
    getCourseList({ keyword: value }).then(res => {
      callback(res.data);
    });
  }

  timeout = setTimeout(fake, 300);
}

export default {
  name: 'addLiveClassLayer',
  data() {
    return {
      visible: false,
      form: {
        teacher_title: '',
      },
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      data: [],
      value: undefined,
      selectClassList: [],
      classes_id: [],
      teacher_title: '',
      courseid: undefined,
    };
  },
  created() {
    this.getClassesSelectList();
    fetch('', data => (this.data = data));
  },
  methods: {
    handleOk() {
      if (!this.value) {
        this.$message.error('请选择直播课！');
        return;
      } else if (!this.form.teacher_title) {
        this.$message.error('请输入讲师！');
        return;
      } else if (!this.classes_id || this.classes_id <= 0) {
        this.$message.error('请选择班次！');
        return;
      }
      this.form.course_id = this.courseid;
      this.form.classes_id = this.classes_id + '';
      this.form.training_id = this.$route.query.id;
      this.saveLiveClass();
    },
    async saveLiveClass() {
      this.loading = true;
      const data = await liveClassAdd(this.form).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.$emit('saveSuccess');
        this.clear();
      }
    },
    async getClassesSelectList() {
      this.loading = true;
      const data = await getClassesSelectList(this.$route.query.id).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.selectClassList = data.data;
      }
    },
    clear() {
      this.value = undefined;
      this.classes_id = undefined;
      this.courseid = undefined;
      this.form = {
        teacher_title: '',
      };
      this.visible = false;
    },
    handleSearch(value) {
      fetch(value, data => (this.data = data));
    },
    handleChange(value) {
      this.value = value;
      fetch(value, data => (this.data = data));
    },
  },
};
</script>

<style scoped>

</style>
